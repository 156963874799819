import React, {useRef, useState} from "react";
import {useOutsideClickDetection} from "../../../lib/hooks/useOutSideClickDetection";
import cs from "classnames";
import chevronDown from "../../../assets/svg/chevron-down.svg";

import "./UnitSelectBox.scss";
import plusIcon from "../../../assets/svg/plus-icon.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ClearAll} from "@mui/icons-material";

export interface ItemsType {
  name: string | undefined | any;
  city_name?: string | undefined;
  title?: any;
  value?: string | undefined;
}

interface UnitSelectBoxProps {
  items?: ItemsType[];
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: any;
  onItemSelect: (name: any) => void;
  onClick: () => void;
  labelStyle?: React.CSSProperties;
  placeholder?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  onAllUnitsClicked: () => void;
}

const UnitSelectBox = (props: UnitSelectBoxProps) => {
  const {
    items, selectedItem, className, className2, style, labelStyle, placeholder, onBlur,
    onChange, onAllUnitsClicked
  } =
    props;
  const {t} = useTranslation("common");
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const navigate = useNavigate();

  const onItemSelect = (name: any) => {

    props.onItemSelect(name);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div ref={dropDownRef} className={cs("UnitSelectBox", className)} style={style}>
      <div className="UnitSelectBox__container" onClick={() => setShowItems(!showItems)}>
        <span
          className="UnitSelectBox__text"
          style={labelStyle}
        >
          {selectedItem?.city_name || placeholder}
        </span>

        <img
          className="UnitSelectBox__icon"
          src={chevronDown}
          alt="chevron down icon"
        />
      </div>
      <div
        className={cs("UnitSelectBox__items", showItems && "active", className2)}
      >
        <div
          className="UnitSelectBox__button"
          onClick={() => {
            onItemSelect({});
            onAllUnitsClicked();
            setShowItems(!showItems);
          }}
        >
          <div className="UnitSelectBox__button-round">
            <ClearAll/>
          </div>
          <p className="UnitSelectBox__add-new">{t("units.addUnitsCart.allUnits")}</p>
        </div>

        {items?.map((item, index) => {
          if (index === 0) {
            return null;
          }
          return (
            <div
              onClick={() => {
                if (!item.city_name) {
                  let nameEng = item?.title?.en;
                  onItemSelect(nameEng);

                } else {
                  onItemSelect(item);
                }

              }}
              className={`UnitSelectBox__item-container ${
                props.selectedItem.city_name === item.city_name ? "activeLabel" : ""
              }`}
              key={index}
            >
              {item.city_name && (<p className="UnitSelectBox__item">{item.city_name}</p>)}
              {/*{item?.title?.en && (<p className="UnitSelectBox__item">{item?.title?.en}</p>)}*/}
            </div>
          );
        })}

      </div>
    </div>
  );
};

export default UnitSelectBox;