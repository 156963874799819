import React, {useState} from "react";
import person from "../../../assets/svg/person.svg";
import clock from "../../../assets/svg/clock.svg";
import {getTimeBetweenDates} from "../../../lib/helpers/getTimeBetweenDates";
import {useNavigate} from "react-router-dom";
import {convertMinsToHrsMins} from "../../../lib/helpers/convertMinsToHrsMins";
import {useTranslation} from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {Countdown} from "../../../components/shared/Countdown/Countdown";

import "./ShortTermJobCard.scss";
import {apiCall} from "../../../api/Api";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import {FileCopy, LowPriority, MoreVert, RotateRight} from "@mui/icons-material";
import ConfirmJobPaymentModal from "../../../components/shared/JobPayment/ConfirmJobPayment";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {IconButton, ListItemIcon, MenuItem, Popover} from "@mui/material";

const ShortTermJobCard = (props: any) => {
  const {job, shiftTotal} = props;
  const globalCtx = useGlobalContext();
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const navigate = useNavigate();
  const {t} = useTranslation("common");
  const maxAvailableTime = dayjs(job?.job_start_time).subtract(12, "hour");
  const currentTime = dayjs();
  const diff = maxAvailableTime?.diff(currentTime, "day", true);
  const days = Math.floor(diff);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showChoosePackage, setShowChoosePackage] = useState(false);
  const [isProJob, setIsProJob] = useState(false);

  return (
    <section className="ShortTermJobCard">
      {job.is_active ? (
        job.is_promoted ? (
          <div className="ShortTermJobCard__header-pro">
            {t("sponsored")}
          </div>
        ) : (
          <div className="ShortTermJobCard__header-basic">
            {/*{t("basic")}*/}
          </div>
        )
      ) : (
        <div className="ShortTermJobCard__header-inactive">
          {t("inactive")}
        </div>
      )}

      <div className="ShortTermJobCard__header">
        <div className="LongTermJobCard__organization-logo-container">
          <img
            alt="pic"
            src={job?.organization?.logo_url}
            className="LongTermJobCard__organization-logo"
          />
        </div>
        <div className="LongTermJobCard__text-container">
          <h2 className="LongTermJobCard__job-title">
            {selectedLanguage === "SQ"
              ? `${job.job_title.title?.sq}`
              : `${job.job_title.title?.en}`}
          </h2>
          <p className="LongTermJobCard__organization-name">
            {job?.organization?.name}
          </p>
        </div>
      </div>

      <div className="ShortTermJobCard__body">
        <div className="ShortTermJobCard__city">
          {job.organization_unit?.city}
        </div>

        <div className="ShortTermJobCard__job-price">
          {job.pay_rate}&euro;/h
        </div>
      </div>
      {job.shifts.length > 1 && (
        <div className="ShortTermJobCard__shift-card-body-another-shift"/>
      )}
      <div className="ShortTermJobCard__shift-card-body">
        <div>
          <div className="ShortTermJobCard__shift-card-start">
            {" "}
            {`${daysOfTheWeek[new Date(job.shifts[0]?.start_time).getDay()]} ${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric"
            })}`}
            {new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            }) !==
            new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            })
              ? ` - ${daysOfTheWeek[new Date(job.shifts[0]?.end_time).getDay()]} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
                month: "short",
                day: "numeric"
              })} `
              : ""}
          </div>

          <div className="NewJobForm__shift-card-end">
            {`${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              hour: "numeric",
              minute: "numeric"
            })} ${t("jobs.shortTermJobCard.to")} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
              hour: "numeric",
              minute: "numeric"
            })} `}
          </div>
        </div>
        {shiftTotal && (
          <div className="ShortTermJobCard__shift-card-total">
            {" "}
            {convertMinsToHrsMins(
              getTimeBetweenDates(
                new Date(job.shifts[0]?.start_time),
                new Date(job.shifts[0]?.end_time),
              ).minutes,
            )}{" "}
            total
          </div>
        )}
      </div>
      <div className="ShortTermJobCard__applicants-wrapper">

      </div>
      <Button
        onClick={() => {
          navigate(`/job/${job?.public_id}`);
        }}
        className={cs("ShortTermJobCard__show-btn")}
        label={t("apply")}
      />
    </section>
  );
};

export default ShortTermJobCard;
