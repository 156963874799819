import React, {useState} from "react";
import person from "../../../assets/svg/person.svg";
import {getTimeBetweenDates} from "../../../lib/helpers/getTimeBetweenDates";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import {apiCall} from "../../../api/Api";

import "./LongTermJobCard.scss";
import {FileCopy, LowPriority, MoreVert, RotateRight} from "@mui/icons-material";
import ConfirmJobPaymentModal from "../../../components/shared/JobPayment/ConfirmJobPayment";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {IconButton, ListItemIcon, MenuItem, Popover} from "@mui/material";

const LongTermJobCard = (props: any) => {
  const {job, shiftTotal} = props;
  const globalCtx = useGlobalContext();
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const navigate = useNavigate();
  const {t} = useTranslation("common");
  const maxAvailableTime = dayjs(job?.job_start_time).subtract(12, "hour");
  const currentTime = dayjs();
  const diff = maxAvailableTime?.diff(currentTime, "day", true);
  const days = Math.floor(diff);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [showChoosePackage, setShowChoosePackage] = useState(false);
  const [isProJob, setIsProJob] = useState(false);
  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);


  const daysArray =
    selectedLanguage === "EN"
      ? ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
      : ["HËN", "MAR", "MËR", "ENJ", "PRE", "SHT", "DIE"];

  return (
    <section className="LongTermJobCard">
      {job.is_active ? (
        job.is_promoted ? (
          <div className="ShortTermJobCard__header-pro">
            {t("sponsored")}
          </div>
        ) : (
          <div className="ShortTermJobCard__header-basic">
            {/*{t("basic")}*/}
          </div>
        )
      ) : (
        <div className="ShortTermJobCard__header-inactive">
          {t("inactive")}
        </div>
      )}

      <div className="LongTermJobCard__header">
        <div className="LongTermJobCard__organization-logo-container">
          <img
            alt="pic"
            src={job?.organization?.logo_url}
            className="LongTermJobCard__organization-logo"
          />
        </div>
        <div className="LongTermJobCard__text-container">
          <h2 className="LongTermJobCard__job-title">
            {selectedLanguage === "SQ"
              ? `${job.job_title.title?.sq}`
              : `${job.job_title.title?.en}`}
          </h2>
          <p className="LongTermJobCard__organization-name">
            {job?.organization?.name}
          </p>
        </div>
      </div>
      <div className="LongTermJobCard__body">
        <div className="LongTermJobCard__city">
          {job.organization_unit?.city}
        </div>
        <div className="LongTermJobCard__job-price">
          {job.schedule.monthly_pay_rate_start && job.schedule.monthly_pay_rate_end ? (
            <>
              {job.schedule.monthly_pay_rate_start}{" "}
              {job.schedule.monthly_pay_rate_end &&
                `- ${job.schedule.monthly_pay_rate_end}`}{" "}
              &euro;/ {t("jobs.longTermJobCard.month")}
            </>
          ) : (
            <div className="EditShortTermJob__payment-not-specified">
              <p>{t("paymentNotSpecified")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="LongTermJobCard__schedule">
        <div className="LongTermJobCard__daysSchedule">
          {daysArray.map((day, index) => (
            <div className="LongTermJobCard__daySchedule" key={index}>
              <div
                className={cs(
                  "LongTermJobCard__daySchedule-head",
                  job?.schedule?.shift_days?.map(
                    (dayIndexes: number) =>
                      dayIndexes == index &&
                      "LongTermJobCard__daySchedule-head-active",
                  ),
                )}
              >
                {day}
              </div>
              <div className="LongTermJobCard__daySchedule-body">
                {job?.schedule?.shift_days?.map(
                  (dayIndexes: number) =>
                    dayIndexes == index && (
                      <>
                        <span>
                          {job?.schedule?.start_time?.substring(0, 5)}
                        </span>{" "}
                        <br/>
                        <span>
                          {job?.schedule?.end_time?.substring(0, 5)}
                        </span>{" "}
                      </>
                    ),
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="LongTermJobCard__jobBenefits">
        <div className="LongTermJobCard__jobBenefits-body">
          {job?.benefits?.map((item: any, index: number) => (
            <div
              key={index}
              className={cs("JobBenefitsCart")}
            >
              <img
                alt="pic"
                src={item.icon}
                className="JobBenefitsCart__icon"
              />
              <div className="JobBenefitsCart__label">
                {selectedLanguage === "SQ" ? item.title.sq : item.title.en}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button
        onClick={() => {
          navigate(`/job/${job?.public_id}`);
        }}
        className={cs("LongTermJobCard__show-btn")}
        label={t("apply")}
      />
    </section>
  );
};

export default LongTermJobCard;
