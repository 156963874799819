import React, {useEffect} from "react";

import {useTranslation} from "react-i18next";
import {apiCall} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import {useResponsiveDimensions} from "../../lib/hooks/useResponsiveDimensions";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import cs from "classnames";

import "./PublicApply.scss";
import PublicApplyForm from "./PublicApplyForm/PublicApplyForm";
import {convertRestApiErrorCodes} from "../../lib/helpers/handleErrors";
import {useGlobalContext} from "../../context/GlobalContext/GlobalContext";
import {convertMinsToHrsMins} from "../../lib/helpers/convertMinsToHrsMins";
import {getTimeBetweenDates} from "../../lib/helpers/getTimeBetweenDates";
import {PhotoProvider, PhotoView} from "react-image-previewer";
import {CloseButton, SlideArrowLeft, SlideArrowRight} from "react-image-previewer/ui";
import {CalendarToday, PlayCircle} from "@mui/icons-material";
import {auth} from "../../firebaseConfig";

const PublicApply = () => {
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();
  let selectedLanguage = localStorage.getItem("lang");
  const isMobile = useResponsiveDimensions().isMobile;
  const navigate = useNavigate();
  const {publicJobId} = useParams();
  const [job, setJob] = React.useState<any>(null);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const daysArray =
    selectedLanguage === "EN"
      ? ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
      : ["HËN", "MAR", "MËR", "ENJ", "PRE", "SHT", "DIE"];

  useEffect(() => {
    console.log("Referrer:", document.referrer);
    if (publicJobId) {
      apiCall("GET", `public/jobs/${publicJobId}`)
        .then((response) => {
          setJob(response.data);
        })
        .catch((error) => {
          const errorMessage = convertRestApiErrorCodes(
            error?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          setJob(null);
          // if code is job_not_found, redirect to 404 page
          if (error?.response?.data?.code === "job_not_found") {
            navigate("/404");
          }
        });
    }
  }, []);

  return (
    <div className="PublicApply">
      {!isMobile && (
        <div className="PublicApply__left">
          <div className="PublicApply__left-container">
            {job && (
              <div className="EditShortTermJob__form">
                <div className="PublicApply__organization-logo-container">
                  <img
                    alt="pic"
                    src={job?.organization?.logo_url || peakJobsLogo}
                    className="PublicApply__organization-logo"
                  />
                </div>
                <div className="EditShortTermJob__item-flex">
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("createCompany.companyDetails.form.companyName.placeholder")}
                    </p>
                    <p className="EditShortTermJob__value">
                      {job?.organization?.name}
                    </p>
                  </div>
                </div>
                <div className="EditShortTermJob__item-flex">
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("jobs.jobDetails.jobTitle.label")}
                    </p>
                    <p className="EditShortTermJob__value">
                      {selectedLanguage === "SQ"
                        ? `${job?.job_title?.title.sq}`
                        : `${job?.job_title?.title.en}`}
                    </p>
                  </div>
                </div>
                {job?.pay_rate && (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("jobs.jobDetails.hourlyRate.label")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        {job?.pay_rate} &#8364;/h
                      </p>
                    </div>
                  </div>
                )}

                {job?.monthly_pay_rate_start || job?.monthly_pay_rate_end ? (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("monthlyPayment")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        {job.schedule.monthly_pay_rate_start}{" "}
                        {job.schedule.monthly_pay_rate_end &&
                          `- ${job.schedule.monthly_pay_rate_end}`}{" "}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("monthlyPayment")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p>{t("paymentNotSpecified")}</p>
                    </div>
                  </div>
                )}
                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("jobs.jobDetails.businessUnit.label")}
                  </p>
                  <p className="EditShortTermJob__value">
                    {job?.organization_unit?.name}
                  </p>
                </div>
                {job?.shifts && job.shifts.length > 0 && (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("jobs.jobDetails.shift.label")}
                    </p>
                    {job.shifts.map((item: any, index: number) => (
                      <div className="EditShortTermJob__shift-container" key={index}>
                        <div>
                          <div className="EditShortTermJob__shift-item">
                            {`${daysOfTheWeek[new Date(item?.start_time).getDay()]} ${new Date(item?.start_time).toLocaleString("en-GB", {
                              month: "short",
                              day: "numeric"
                            })}`}
                            {new Date(item?.end_time).toLocaleString("en-GB", {
                              month: "short",
                              day: "numeric",
                            }) !==
                            new Date(item?.start_time).toLocaleString("en-GB", {
                              month: "short",
                              day: "numeric",
                            })
                              ? ` - ${daysOfTheWeek[new Date(item?.end_time).getDay()]} ${new Date(item?.end_time).toLocaleString("en-GB", {
                                month: "short",
                                day: "numeric"
                              })} `
                              : ""}
                          </div>
                          <div className="EditShortTermJob__shift-item-small">
                            {`${new Date(item?.start_time).toLocaleString("en-GB", {
                              hour: "numeric",
                              minute: "numeric"
                            })} ${t("jobs.jobDetails.shift.to")} ${new Date(item?.end_time).toLocaleString("en-GB", {
                              hour: "numeric",
                              minute: "numeric"
                            })} `}
                          </div>
                        </div>
                        <div className="EditShortTermJob__shift-item">
                          {convertMinsToHrsMins(
                            getTimeBetweenDates(
                              new Date(item?.start_time),
                              new Date(item?.end_time),
                            ).minutes,
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("starts")}
                  </p>
                  <div className="EditShortTermJob__input-container">
                    <p className="EditShortTermJob__value">
                      <PlayCircle sx={{fontSize: 21, textAlign: 'center'}}/>
                      {job?.job_start_time ? (
                        new Date(job?.job_start_time).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      ) : (
                        t("immediately")
                      )}
                    </p>
                  </div>
                </div>
                {job?.schedule && (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("duration")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        <CalendarToday sx={{
                          fontSize: 21,
                          textAlign: 'center'
                        }}/>
                        {job?.schedule?.length_in_months === 0 ? t("moreThanAYear") : job?.schedule?.length_in_months} {t("jobs.longTermJobCard.month")}
                      </p>
                    </div>
                  </div>
                )}
                {job?.schedule && (
                  <div className="LongTermJobCard__schedule">
                    <div className="LongTermJobCard__daysSchedule">
                      {daysArray.map((day, index) => (
                        <div className="LongTermJobCard__daySchedule" key={index}>
                          <div
                            className={cs(
                              "LongTermJobCard__daySchedule-head",
                              job?.schedule?.shift_days?.map(
                                (dayIndexes: number) =>
                                  dayIndexes == index &&
                                  "LongTermJobCard__daySchedule-head-active",
                              ),
                            )}
                          >
                            {day}
                          </div>
                          <div className="LongTermJobCard__daySchedule-body">
                            {job?.schedule?.shift_days?.map(
                              (dayIndexes: number) =>
                                dayIndexes == index && (
                                  <>
                        <span>
                          {job?.schedule?.start_time?.substring(0, 5)}
                        </span>{" "}
                                    <br/>
                                    <span>
                          {job?.schedule?.end_time?.substring(0, 5)}
                        </span>{" "}
                                  </>
                                ),
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("jobs.jobDetails.description.label")}
                  </p>
                  {job.applications_count > 0 ? (
                    <div className="EditShortTermJob__input-container">
                      <div className="EditShortTermJob__textarea-desc">
                        {job.description}
                      </div>
                    </div>
                  ) : (
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        {job.description}
                      </p>

                    </div>
                  )}
                </div>
                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("jobs.jobDetails.images.label")}
                  </p>
                  <PhotoProvider
                    maskOpacity={0.7}
                    overlayRender={(props) => {
                      return (
                        <>
                          <SlideArrowLeft {...props} />
                          <SlideArrowRight {...props} />
                          <CloseButton onClick={props.onClose}/>
                        </>
                      );
                    }}
                  >
                    <div className="EditShortTermJob__images">
                      {job?.image_urls?.length !== 0 &&
                        job?.image_urls?.map((item: any, index: number) => (
                          <div
                            className="EditShortTermJob__image-preview-container"
                            key={index}
                          >
                            <PhotoView src={item} key={index}>
                              <img
                                alt="image preview"
                                src={item}
                                className="EditShortTermJob__image-preview"
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  objectFit: "cover",
                                  borderRadius: "6px",
                                }}
                              />
                            </PhotoView>
                          </div>
                        ))}
                    </div>
                  </PhotoProvider>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className={cs(
          "PublicApply__right",
          isMobile && "PublicApply__right-mobile",
        )}
      >
        <div className="PublicApply__right-container">
          {isMobile &&
            job && (

              <div className="EditShortTermJob__form">
                {/* <div className="PublicApply__right-head">*/}
                {/*   <img*/}
                {/*     alt="pic"*/}
                {/*    src={job?.organization?.logo_url || peakJobsLogo}*/}
                {/*    className="PublicApply__organization-logo-mobile"*/}
                {/*  />*/}
                {/*  <p className="PublicApply__right-title">*/}
                {/*    {job?.organization?.name}*/}
                {/*    &nbsp; - &nbsp;*/}
                {/*    {job?.job_title_text}*/}
                {/*  </p>*/}
                {/*</div>*/}
                <div className="PublicApply__organization-logo-container">
                  <img
                    alt="pic"
                    src={job?.organization?.logo_url || peakJobsLogo}
                    className="PublicApply__organization-logo"
                  />
                </div>
                <div className="EditShortTermJob__item-flex">
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("createCompany.companyDetails.form.companyName.placeholder")}
                    </p>
                    <p className="EditShortTermJob__value">
                      {job?.organization?.name}
                    </p>
                  </div>
                </div>
                <div className="EditShortTermJob__item-flex">
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("jobs.jobDetails.jobTitle.label")}
                    </p>
                    <p className="EditShortTermJob__value">
                      {selectedLanguage === "SQ"
                        ? `${job?.job_title?.title.sq}`
                        : `${job?.job_title?.title.en}`}
                    </p>
                  </div>
                </div>
                {job?.pay_rate && (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("jobs.jobDetails.hourlyRate.label")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        {job?.pay_rate} &#8364;/h
                      </p>
                    </div>
                  </div>
                )}

                {job?.monthly_pay_rate_start || job?.monthly_pay_rate_end ? (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("monthlyPayment")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        {job.schedule.monthly_pay_rate_start}{" "}
                        {job.schedule.monthly_pay_rate_end &&
                          `- ${job.schedule.monthly_pay_rate_end}`}{" "}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("monthlyPayment")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p>{t("paymentNotSpecified")}</p>
                    </div>
                  </div>
                )}
                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("jobs.jobDetails.businessUnit.label")}
                  </p>
                  <p className="EditShortTermJob__value">
                    {job?.organization_unit?.name}
                  </p>
                </div>
                {job?.shifts && job.shifts.length > 0 && (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("jobs.jobDetails.shift.label")}
                    </p>
                    {job.shifts.map((item: any, index: number) => (
                      <div className="EditShortTermJob__shift-container" key={index}>
                        <div>
                          <div className="EditShortTermJob__shift-item">
                            {`${daysOfTheWeek[new Date(item?.start_time).getDay()]} ${new Date(item?.start_time).toLocaleString("en-GB", {
                              month: "short",
                              day: "numeric"
                            })}`}
                            {new Date(item?.end_time).toLocaleString("en-GB", {
                              month: "short",
                              day: "numeric",
                            }) !==
                            new Date(item?.start_time).toLocaleString("en-GB", {
                              month: "short",
                              day: "numeric",
                            })
                              ? ` - ${daysOfTheWeek[new Date(item?.end_time).getDay()]} ${new Date(item?.end_time).toLocaleString("en-GB", {
                                month: "short",
                                day: "numeric"
                              })} `
                              : ""}
                          </div>
                          <div className="EditShortTermJob__shift-item-small">
                            {`${new Date(item?.start_time).toLocaleString("en-GB", {
                              hour: "numeric",
                              minute: "numeric"
                            })} ${t("jobs.jobDetails.shift.to")} ${new Date(item?.end_time).toLocaleString("en-GB", {
                              hour: "numeric",
                              minute: "numeric"
                            })} `}
                          </div>
                        </div>
                        <div className="EditShortTermJob__shift-item">
                          {convertMinsToHrsMins(
                            getTimeBetweenDates(
                              new Date(item?.start_time),
                              new Date(item?.end_time),
                            ).minutes,
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("starts")}
                  </p>
                  <div className="EditShortTermJob__input-container">
                    <p className="EditShortTermJob__value">
                      <PlayCircle sx={{fontSize: 21, textAlign: 'center'}}/>
                      {job?.job_start_time ? (
                        new Date(job?.job_start_time).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      ) : (
                        t("notSpecified")
                      )}
                    </p>
                  </div>
                </div>
                {job?.schedule && (
                  <div className="EditShortTermJob__item">
                    <p className="EditShortTermJob__label">
                      {t("duration")}
                    </p>
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        <CalendarToday sx={{
                          fontSize: 21,
                          textAlign: 'center'
                        }}/>
                        {job?.schedule?.length_in_months === 0 ? t("moreThanAYear") : job?.schedule?.length_in_months} {t("jobs.longTermJobCard.month")}
                      </p>
                    </div>
                  </div>
                )}
                {job?.schedule && (
                  <div className="LongTermJobCard__schedule">
                    <div className="LongTermJobCard__daysSchedule">
                      {daysArray.map((day, index) => (
                        <div className="LongTermJobCard__daySchedule" key={index}>
                          <div
                            className={cs(
                              "LongTermJobCard__daySchedule-head",
                              job?.schedule?.shift_days?.map(
                                (dayIndexes: number) =>
                                  dayIndexes == index &&
                                  "LongTermJobCard__daySchedule-head-active",
                              ),
                            )}
                          >
                            {day}
                          </div>
                          <div className="LongTermJobCard__daySchedule-body">
                            {job?.schedule?.shift_days?.map(
                              (dayIndexes: number) =>
                                dayIndexes == index && (
                                  <>
                        <span>
                          {job?.schedule?.start_time?.substring(0, 5)}
                        </span>{" "}
                                    <br/>
                                    <span>
                          {job?.schedule?.end_time?.substring(0, 5)}
                        </span>{" "}
                                  </>
                                ),
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("jobs.jobDetails.description.label")}
                  </p>
                  {job.applications_count > 0 ? (
                    <div className="EditShortTermJob__input-container">
                      <div className="EditShortTermJob__textarea-desc">
                        {job.description}
                      </div>
                    </div>
                  ) : (
                    <div className="EditShortTermJob__input-container">
                      <p className="EditShortTermJob__value">
                        {job.description}
                      </p>

                    </div>
                  )}
                </div>
                <div className="EditShortTermJob__item">
                  <p className="EditShortTermJob__label">
                    {t("jobs.jobDetails.images.label")}
                  </p>
                  <PhotoProvider
                    maskOpacity={0.7}
                    overlayRender={(props) => {
                      return (
                        <>
                          <SlideArrowLeft {...props} />
                          <SlideArrowRight {...props} />
                          <CloseButton onClick={props.onClose}/>
                        </>
                      );
                    }}
                  >
                    <div className="EditShortTermJob__images">
                      {job?.image_urls?.length !== 0 &&
                        job?.image_urls?.map((item: any, index: number) => (
                          <div
                            className="EditShortTermJob__image-preview-container"
                            key={index}
                          >
                            <PhotoView src={item} key={index}>
                              <img
                                alt="image preview"
                                src={item}
                                className="EditShortTermJob__image-preview"
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  objectFit: "cover",
                                  borderRadius: "6px",
                                }}
                              />
                            </PhotoView>
                          </div>
                        ))}
                    </div>
                  </PhotoProvider>
                </div>
              </div>
            )}

          <div className="PublicApply__right-body">
            <PublicApplyForm
              publicJobId={publicJobId}
              referrer={document.referrer || "unknown"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicApply;
