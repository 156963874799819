import React, {useState, useEffect} from "react";

import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {apiCall} from "../../../api/Api";

import classNames from "classnames";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ShortTermJobCard from "../ShortTermJobCard/ShortTermJobCard";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import reloadIcon2 from "../../../assets/svg/Refresh_icon.svg";

import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {convertRestApiErrorCodes} from "../../../lib/helpers/handleErrors";

import "./LongTermNotAssigned.scss";
import LongTermJobCard from "../LongTermJobCard/LongTermJobCard";
import UnitSelectBox from "../../../components/shared/SelectBox/UnitSelectBox";
import peakJobsLogo from "../../../assets/svg/peak-jobs-logo.svg";

const LongTermNotAssigned = () => {
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const {countryCode = "XK", organizationId} = useParams();
  const uppercaseCountryCode = organizationId ? "" : countryCode.toUpperCase(); // ignore if org id is provided
  const [allAvailableJobs, setAllAvailableJobs] = useState<any | null>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<any>({});
  const [organization, setOrganization] = useState<any>({});
  const [organizationCountry, setOrganizationCountry] = useState<String | null>(null);

  useEffect(() => {
    callJobs();
  }, [selectedUnit]);

  useEffect(() => {
    getOrganizationCities();
  }, [organizationCountry]);

  useEffect(() => {
    if (!organizationId) {
      // Handle the case where organizationId is not provided
      console.log("No organizationId provided.");
      return;
    }
    const fetchOrganization = async () => {
      if (!organizationId) {
        // Handle the case where organizationId is not provided
        console.log("No organizationId provided.");
        return;
      }
      try {
        const res = await apiCall("get", `b2b/organization/public/${organizationId}/`);
        setOrganization(res.data);
        setOrganizationCountry(res.data.country);
      } catch (err) {
        console.log(err);
      }
    };

    fetchOrganization();
  }, [organizationId]);


  const getOrganizationCities = () => {
    if (!organizationCountry) {
      return;
    }
    console.log("organizationCountry", organizationCountry);
    apiCall("get", `b2c/jobs/cities/?country=${organizationCountry || "XK"}`)
      .then((res: any) => {
        setBusinessUnits(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch business units:", err);
        // Handle the error, e.g., set an error state or show a message to the user
      });
  }

  useEffect(() => {
    if (!organizationId) {
      apiCall("get", "b2c/jobs/cities/?country=" + uppercaseCountryCode)
        .then((res: any) => {
          setBusinessUnits(res.data);
        })
        .catch((err) => {
          console.error("Failed to fetch business units:", err);
          // Handle the error, e.g., set an error state or show a message to the user
        });
    }
  }, []);

  const callJobs = () => {
    setIsLoading(true);
    const url = `b2c/long-term-jobs/guest/?limit=10&city=${selectedUnit?.city_name || ""}&organization=${organizationId || ""}&country=${uppercaseCountryCode}`;

    apiCall("GET", url)
      .then((res) => {
        setAllAvailableJobs(res.data.results);
        // filterDates(res.data.results);
        setIsCalled(true);
        setIsLoading(false);
        setNextPage(res.data?.next);
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    if (!isCalled) {
      callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2c/long-term-jobs/guest/?${resultUrl}`)
        .then((res) => {
          setAllAvailableJobs([...allAvailableJobs, ...res.data.results]);
          // filterDates([...allAvailableJobs, ...res.data.results]);
          setIsCalled(true);
          setIsLoading(false);
          setNextPage(res.data?.next);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <div className="LongTermNotAssigned">
      {organization && (
        <div className="LongTermNotAssigned__header-container">
          <div className="PublicApply__organization-logo-container">
            <img
              alt="pic"
              src={organization?.logo_url || peakJobsLogo}
              className="PublicApply__organization-logo"
            />
          </div>
          {organization?.name && (
            <div className="LongTermNotAssigned__organization-name-container">
              <div className="LongTermNotAssigned__org-hint-item">
                <p className="EditShortTermJob__label">
                  {t("createCompany.companyDetails.form.companyName.placeholder")}
                </p>
                <p className="EditShortTermJob__value">
                  {organization?.name}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="LongTermNotAssigned__header-container">

        <div className="LongTermNotAssigned__title-container">
          <div className="LongTermNotAssigned__main-title">
            {t("jobs.title")}
          </div>
          {isLoading ? (
            <div className="LongTermNotAssigned__spinner"/>
          ) : (
            <img
              src={reloadIcon2}
              alt="reload icon"
              className={classNames(
                "LongTermNotAssigned__reload-icon",
                refreshCalled && "LongTermNotAssigned__reload-icon--disable",
              )}
              onClick={() => {
                if (!refreshCalled) {
                  callJobs();
                  setRefreshCalled(true);
                  setTimeout(() => {
                    setRefreshCalled(false);
                  }, 10000);
                }
              }}
            />
          )}
          <UnitSelectBox
            items={businessUnits}
            selectedItem={selectedUnit}
            onItemSelect={(item: any) => {
              setSelectedUnit(item); // Set selected unit only
            }}
            placeholder={t("jobs.newShortTermJob.jobDetails.businessUnit.placeholder")}
            onClick={() => {
            }}
            onBlur={(e: any) => {
            }}
            onChange={(e: any) => {
            }}
            onAllUnitsClicked={() => {
              setSelectedUnit({}); // Clear selected unit for "All" option
            }}
          />
        </div>
      </div>
      <section className="LongTermNotAssigned__container">
        <div
          onClick={() => {
            navigate(`/${uppercaseCountryCode || "xk"}/jobs/short-term/${organizationId || ""}`);
          }}
          className={classNames("LongTermNotAssigned__container-item")}
        >
          {t("jobs.shortTermTabLabel")}
        </div>
        <div
          onClick={() => {
            navigate(`/${uppercaseCountryCode || "xk"}/jobs/${organizationId || ""}`);
          }}
          className={classNames(
            "LongTermNotAssigned__container-item",
            "LongTermNotAssigned__container-item--active",
          )}
        >
          {t("jobs.longTermTabLabel")}
        </div>
      </section>
      {allAvailableJobs?.length === 0 && isCalled && !loading && (
        <div className="LongTermNotAssigned__no-content">
          {t("noAvailableJobs")}
        </div>
      )}
      {allAvailableJobs?.length > 0 && (
        <div className="LongTermNotAssigned__timeline">
          <div className="LongTermNotAssigned__timeline-container">
            <div className="LongTermNotAssigned__timeline-content">
              {allAvailableJobs?.map((job: any, index: number) => (
                <div key={index} className="LongTermNotAssigned__job-wrapper">
                  <LongTermJobCard job={job} shiftTotal={true}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="LongTermNotAssigned__infinite-scroll-wrapper">
        {nextPage && true && (
          <div ref={sentryRef}>
            <LoadingComponent/>
          </div>
        )}
      </div>

      {isLoading && allAvailableJobs.length === 0 && (
        <div style={{margin: "150px auto"}}>
          <LoadingComponent/>
        </div>
      )}
    </div>
  );
};

export default LongTermNotAssigned;
